import React from "react";

export default function Footer() {
  return (
    <div className="App">
      <div className="footer center">
        <div className="w-container">
          <div className="footer-text">Copyright All America Trading LLC</div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="App">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="navigation-bar w-nav"
      >
        <div className="w-container">
          <Link to="/" className="brand-link w-clearfix w-nav-brand w--current">
            <img
              src="images/AATIcon.jpg"
              width="100"
              height="100"
              alt=""
              className="image"
            />
            <div className="text-block">All America Trading</div>
          </Link>
          <nav role="navigation" className="navigation-menu w-nav-menu">
            <Link to="/" className="navigation-link w-nav-link w--current">
              Inicio
            </Link>
            <Link to="/contact" className="navigation-link w-nav-link">
              Contacto
            </Link>
          </nav>
          <div className="hamburger-button w-nav-button">
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

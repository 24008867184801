import React from "react";

export default function Confirmation() {
  return (
    <div className="App">
      <div className="section accent">
        <div className="w-container">
          <div className="section-title-group">
            <h2 className="heading-2">Confirmacion</h2>
            <br />
            <div className="">
              Gracias por hacer tu orden con All American Trading.
              <br /> Recibiras un email con la confirmacion de pago dentro de
              24/48 horas.
              <br />
              <br />
              De tener alguna duda se puede comunicar con nosotros por email a
              info@aatrading.co
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function Contact() {
  return (
    <div className="App">
      <div
        data-widget-latlng="37.77493,-122.419416"
        data-widget-style="roadmap"
        data-widget-zoom="12"
        data-disable-scroll="1"
        className="w-widget w-widget-map"
      ></div>
      <div className="section">
        <div className="w-container">
          <div className="section-title-group">
            <h2 className="section-heading centered">Contactenos</h2>
            <div className="section-subheading center">
             All American Trading estamos para servirle.
            </div>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique. <br />
            <br />
            Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
            commodo diam libero vitae erat. Aenean faucibus nibh et <br />
            justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus
            tristique posuere.
          </p>
          <div className="form-wrapper w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form"
            >
              <input
                type="text"
                className="form-field w-input"
                name="Name"
                placeholder="Enter your name..."
              />
              <input
                type="email"
                className="form-field w-input"
                name="Email"
                placeholder="Enter your email..."
              />
              <textarea
                id="field-2"
                name="field-2"
                placeholder="Mensaje"
                className="form-field text-area w-input"
              ></textarea>
              <input
                type="submit"
                value="Enviar"
                className="button full-width w-button"
              />
            </form>
            <div className="w-form-done">
              <p>Thank you! Your submission has been received!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="App">
      <div className="hero-section centered">
        <div data-ix="new-interaction" className="w-container">
          <h1  className="hero-heading">
            All america trading
          </h1>
        </div>
      </div>
      <div className="section accent">
        <div className="w-container">
          <div className="section-title-group">
            <div className="section-subheading center off-white">
              no salgas de casa,
              <br />
            </div>
            <h1 className="heading">!Protége!</h1>
            <div className="section-subheading center off-white">
              nosotros te lo
              <br />
            </div>
            <h2 className="heading-2">llevamos a tu hogar</h2>
          </div>
        </div>
        <div className="container w-container">
          <div className="w-row">
            <div className="w-col w-col-6">
              <div className="white-box transparent">
                <h3 className="white-text">paquete individual</h3>
                <div className="pricing-details-wrap">
                  <div className="pricing">$55</div>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">2 Mascarillas</p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">
                    15 Pares de guantes &quot;Medium&quot;
                  </p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">1 Termómetro</p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">
                    2 Botellas 2oz &quot;Spray&quot; para Alcohol
                  </p>
                </div>

                <p className="white-text">
                  <strong>Manejo</strong> y <strong>Envio</strong> incluido PR
                </p>
                <div className="w-form">
                  <div className="buydiv">
                  <Link
                      to={{
                        pathname: "/summary",
                        state: { packages: "Paquete Individual", price: "55.00" },
                      }}
                      className="pricing-button w-button"
                    >
                      Comprar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-col w-col-6">
              <div className="white-box transparent">
                <h3 className="white-text">
                  paquete
                  <br />
                  familiar
                </h3>
                <div className="pricing-details-wrap">
                  <div className="pricing">$70</div>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">8 Mascarillas</p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">
                    Caja de 100 guantes &quot;Medium&quot;
                  </p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">2 Termómetros</p>
                </div>
                <div className="pricing-check">
                  <img
                    src="https://uploads-ssl.webflow.com/5aa5deb2f3d89b000123c7dd/5cd24ca168db6560f9e01747_check.svg"
                    alt=""
                    className="check"
                  />
                  <p className="pricing-text">
                    8 Botellas 2oz &quot;Spray&quot; para Alcohol
                  </p>
                </div>
                <p className="white-text">
                  <strong>Manejo</strong> y <strong>Envio</strong> incluido PR
                </p>
                <div className="w-form">
                  <div className="buydiv">
                    <Link
                      to={{
                        pathname: "/summary",
                        state: { packages: "Paquete Familiar", price: "70.00" },
                      }}
                      className="pricing-button w-button"
                    >
                      Comprar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./summary.scss";
import { useLocation, useHistory } from "react-router-dom";
import Api from "../../helpers/Api";

interface IFormInputs {
  name: string;
  email: string;
  phone: string;
  address: string;
  zipCode: string;
  city: string;
  creditCard: string;
  ccv: string;
  month: string;
  year: string;
}

const Summary = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState({
    packages: "",
    price: "",
  });
  const { packages, price } = state;

  useEffect(() => {
    const data: any = location.state;
    const { packages, price } = data;
    setState({
      packages: packages,
      price: price,
    });
    console.log(packages);
    console.log(price);
  }, [location]);

  const initialValues: IFormInputs = {
    name: "",
    email: "",
    phone: "",
    address: "",
    zipCode: "",
    city: "",
    creditCard: "",
    ccv: "",
    month: "",
    year: "",
  };

  const validate = (values: any) => {
    const errors: any = {};
    const regexp = /^[0-9\b]+$/;
    if (!values.name) {
      errors.name = "Campo Requerido";
    }
    if (!values.email) {
      errors.email = "Campo Requerido";
    }

    if (!values.phone) {
      errors.phone = "Campo Requerido";
    }
    if (!values.address) {
      errors.address = "Campo Requerido";
    }
    if (!values.zipCode) {
      errors.zipCode = "Campo Requerido";
    }
    if (!values.city) {
      errors.city = "Campo Requerido";
    }
    if (!values.creditCard || values.creditCard.length < 15) {
      errors.creditCard = "Campo Requerido";
    }
    if (!regexp.test(values.creditCard)) {
      errors.creditCard = "Numeros requeridos";
    }

    if (!values.ccv) {
      errors.ccv = "Campo Requerido";
    }
    if (!values.month) {
      errors.month = "Campo Requerido";
    }
    if (!values.year) {
      errors.year = "Campo Requerido";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (values) => {
      const data = {
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phone,
        address: formik.values.address,
        zipCode: String(formik.values.zipCode),
        city: formik.values.city,
        creditCard: formik.values.creditCard,
        ccv: formik.values.ccv,
        month: formik.values.month,
        year: formik.values.year,
        price: price
      };

      const customerWithPlan = await Api.post('/createCustomer', data);
      if(customerWithPlan.status === 201){
        history.push('/confirmation');
      }
      console.log(customerWithPlan)

    },
  });

  return (
    <div className="App">
      <div className="section accent">
        <div className="formdiv w-container">
          <div className="w-row">
            <form id="email-form" onSubmit={formik.handleSubmit}>
              <div className="w-col w-col-7">
                <div className="w-form">
                  <h4 className="heading-3">Informacion Personal</h4>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-5" className="formlabel">
                        Nombre
                      </label>
                      <input
                        type="text"
                        className="w-input"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.errors.name && (
                        <div className="textfield-error-label">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                    <div className="inputdiv left10">
                      <label htmlFor="address-5" className="formlabel">
                        Email
                      </label>
                      <input
                        type="email"
                        className="w-input"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <div className="textfield-error-label">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-5" className="formlabel">
                        Telefono
                      </label>
                      <input
                        type="tel"
                        className="w-input"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone && (
                        <div className="textfield-error-label">
                          {formik.errors.phone}
                        </div>
                      )}
                    </div>
                    <div className="inputdiv left10"></div>
                  </div>
                  <h4 className="heading-3">Direccion</h4>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-4" className="formlabel">
                        Direccion
                      </label>
                      <input
                        type="text"
                        className="w-input"
                        name="address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                      />
                      {formik.errors.address && (
                        <div className="textfield-error-label">
                          {formik.errors.address}
                        </div>
                      )}
                    </div>
                    <div className="inputdiv left10">
                      <label htmlFor="address-4" className="formlabel">
                        Codigo Postal
                      </label>
                      <input
                        type="number"
                        className="w-input"
                        name="zipCode"
                        onChange={formik.handleChange}
                        value={formik.values.zipCode}
                      />
                      {formik.errors.zipCode && (
                        <div className="textfield-error-label">
                          {formik.errors.zipCode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-5" className="formlabel">
                        Ciudad
                      </label>
                      <input
                        type="text"
                        className="w-input"
                        name="city"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                      />
                      {formik.errors.city && (
                        <div className="textfield-error-label">
                          {formik.errors.city}
                        </div>
                      )}
                    </div>
                  </div>
                  <h4 className="heading-3">Informacion de Pago</h4>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-4" className="formlabel">
                        Numero de Tarjeta
                      </label>
                      <input
                        type="text"
                        className="w-input"
                        name="creditCard"
                        onChange={formik.handleChange}
                        value={formik.values.creditCard}
                        minLength={15}
                        maxLength={16}
                      />
                      {formik.errors.creditCard && (
                        <div className="textfield-error-label">
                          {formik.errors.creditCard}
                        </div>
                      )}
                    </div>
                    <div className="inputdiv left10">
                      <label htmlFor="address-4" className="formlabel">
                        CCV
                      </label>
                      <input
                        type="text"
                        className="w-input"
                        name="ccv"
                        onChange={formik.handleChange}
                        value={formik.values.ccv}
                      />
                      {formik.errors.ccv && (
                        <div className="textfield-error-label">
                          {formik.errors.ccv}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="inputcontainers">
                    <div className="inputdiv right10">
                      <label htmlFor="address-4" className="formlabel">
                        Mes
                      </label>
                      <select
                        id="month-2"
                        name="month"
                        onChange={formik.handleChange}
                        value={formik.values.month}
                        className="dropdownfield w-select"
                      >
                        <option value="">Select one...</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      {formik.errors.month && (
                        <div className="textfield-error-label">
                          {formik.errors.month}
                        </div>
                      )}
                    </div>
                    <div className="inputdiv left10">
                      <label htmlFor="address-4" className="formlabel">
                        Ano
                      </label>
                      <select
                        id="year-2"
                        name="year"
                        onChange={formik.handleChange}
                        value={formik.values.year}
                        className="dropdownfield w-select"
                      >
                        <option value="">Select one...</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                      </select>
                      {formik.errors.year && (
                        <div className="textfield-error-label">
                          {formik.errors.year}
                        </div>
                      )}
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className="w-col w-col-5">
                <h4 className="heading-3">Your order</h4>
                <div className="line margin-top-45"></div>
                <div className="orderitems">
                  <div className="w-row">
                    <div className="w-col w-col-7">
                      <div className="orderitemlabel">{packages}</div>
                    </div>
                    <div className="w-col w-col-2"></div>
                    <div className="w-col w-col-3">
                      <div className="orderitemlabel">${price}</div>
                    </div>
                  </div>
                </div>

                <div className="line"></div>
                <div className="pricediv">
                  <div className="pricelabel">Subtotal</div>
                  <div className="pricelabel">${price}</div>
                </div>
                <div className="pricediv">
                  <div className="pricelabel">Shipping</div>
                  <div className="pricelabel">Free</div>
                </div>
                <div className="pricediv">
                  <div className="pricelabel">Total</div>
                  <div className="pricelabel">${price}</div>
                </div>
                <div className="line"></div>
              </div>
              <input
                    type="submit"
                    value="Finalizar"
                    className="submit-button w-button"
                  />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Summary;
